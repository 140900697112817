<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
                        <!-- <p v-b-modal.modal-3 variant="link" class="mb-0"><u>판매량 모니터링 상품 추가 방법</u></p>
                        <div v-if="showspinner"><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div>
                            <b-modal id="modal-3" size="xl" title="쿠팡 모니터링 상품 추가 방법" ok-only>
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 쿠플러스에 로그인한 다음 <b style="color:red"><u>"쿠팡 상품페이지"에서</u></b> "링크수집(판매량)" 버튼을 클릭해주세요.<br> 한번 추가된 상품은 매일 1회 자동으로 수집됩니다.</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/모니터링추가.png')">
                            </b-modal> -->
					</div>
					<!-- <b-button variant="primary pl-5 pr-5" class="mt-2 mr-1" @click="collecttr">{{linktext}}</b-button> -->
                </div>
			</div>

			<!-- <div class="col-lg-12" v-if="showlist">
				<b-button variant="outline-primary" class="mb-1 mr-2" @click="showts">
				트렌드세터
				</b-button>
				<b-button variant="outline-secondary" class="mb-1 mr-2" @click="showtf">
				트렌드팔로워
				</b-button>

			</div> -->

			<!-- <div class="col-lg-6 mt-3" v-if="showlist">
				<b-button variant="outline-success pl-5 pr-5" class="mt-2 mr-1 mb-2" v-b-toggle.my-collapse>판매량 보정</b-button>
				<b-collapse id="my-collapse">
					<b-card title="">
						보정치 적용 시 마이너스 판매량을 0으로 바꾸어 줍니다. (마이너스 임계치가 -2일경우 -2이하의 판매량은 모두 0으로 적용)
						<br>
						<br>
						[마이너스 임계치]
						<div class="form-group d-inline-block d-flex justify-content-between">
							<input type="number" class="form-control" placeholder="마이너스 임계치" v-model="minuslimit">

						</div>
						<div class="custom-control custom-switch custom-control-inline">
								<input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="applyminuslimit" @change="applycorrect">
								<label class="custom-control-label" for="customSwitch2" v-b-tooltip.bottom title="전체 페이지에서 검색하며, 여러개가 검색될 경우 페이지랭킹이 여러게로 나타납니다.">보정치 적용</label>
							</div>
					</b-card>
				</b-collapse>
			</div> -->

			<div class="col-lg-12 mt-3" v-if="showlist" style="font-size:80%">
                <CDataTable :headers="headers" :items="saleslists" v-model="tableSelectedItem" :show-select="true" itemKey="_id" style="margin-bottom: 10px" :page="page" @changePage="changePage">
                    <template v-slot:header="{ props }">
                        <tr>
                            <th :colspan="props.headers.length">소싱방법 <br /> Badge</th>
                        </tr>
                    </template>
                    <template v-slot:로켓뱃지="{item}">
                        <div style="width:50px;overflow:hidden;text-overflow: ellipsis;">
                            <div>{{ item.type == 'trendSetter' ? 'Trend Setter' : 'Trend Follower' }}</div>
                            <span v-if="!item.로켓뱃지">없음</span><img v-if="item.로켓뱃지" :src="item.로켓뱃지" alt="image" style="max-width:100%">
                        </div>
                    </template>
                    <template v-slot:판매상품명="{item}">
                        <div style="min-width:250px;overflow:hidden;text-overflow: ellipsis; padding: 10px">
                            <div class="d-flex align-items-center">
                                <img :src="item.썸네일" class="img-fluid rounded avatar-100 mr-3" alt="image" @click="openWebsite(item.판매링크)">
                                <div>
                                    {{item.판매상품명}}
                                    <p class="mb-0"><small>ProductId: {{item.productId}}</small></p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:구매가="{item}">
                        <div class="d-flex flex-column mt-1 w-100">
                            <div class="d-flex justify-content-between align-items-center w-100 text-center">
                                CNY <input type="number" class="form-control ml-1 text-center" placeholder="구매가(CNY)" v-model="item.구매가" @change="changeCost(item)">
                            </div>
                            <div class="d-flex justify-content-between align-items-center w-100 text-center">
                                &nbsp;&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;&nbsp; <input type="number" class="form-control ml-1 text-center" placeholder="매입가율" v-model="item.매입가율" @change="changeCost(item)">
                            </div>
                        </div>
                    </template>
                    <template v-slot:판매가="{item}">
                        <input type="number" class="form-control w-100 text-center" placeholder="판매가" v-model="item.판매가" @change="changeCost(item)">
                    </template>
                    <template v-slot:마진="{item}">
                        <td style="overflow:hidden;text-overflow: ellipsis;">{{item.마진.toLocaleString()}}<br>{{item.마진율 ? item.마진율.toFixed(1) + '%' : ''}}<br>{{item.ROI ? item.ROI.toFixed(1) + '%' : ''}}</td>
                    </template>
                    <template v-slot:수수료율="{item}">
                        {{item.수수료.toLocaleString()}}
                        <input type="number" class="form-control w-100 text-center" placeholder="수수료율" v-model="item.수수료율" @change="changeCost(item)">
                    </template>
                    <template v-slot:월매출="{item}">
                        {{item.월매출.toLocaleString()}}
                    </template>
					<template v-slot:메모="{item}">
						<input type="text" class="form-control w-100 text-center" placeholder="" v-model="item.메모">
					</template>
                </CDataTable>
				<div class="d-flex justify-content-start">
					<button type="button" class="btn btn-success mr-2 mb-4" @click="saveStatus">현재상태저장</button>
					<button type="button" class="btn btn-primary mr-2 mb-4" @click="saveitem">상품찜</button>
					<button type="button" class="btn btn-secondary mr-2 mb-4" @click="deleteitem">삭제</button>
					<button type="button" class="btn btn-success mr-2 mb-4" @click="downloadsales">다운로드</button>
					<button type="button" class="btn btn-danger mr-2 mb-4" v-b-toggle.my-collapse>일괄변경</button>
				</div>

					<b-collapse id="my-collapse">
						<b-card title="">
							<div class="form-group">
								<input type="text" class="form-control" placeholder="구매가(CNY)" v-model="일괄구매가">
							</div>
							<div class="form-group">
								<input type="number" class="form-control" placeholder="매입가율" v-model="일괄매입가율">
							</div>
							<div class="form-group">
								<input type="text" class="form-control" placeholder="판매가" v-model="일괄판매가">
							</div>
							<button type="button" class="btn btn-success mr-2 mt-1" @click="editlists">적용</button>
						</b-card>
					</b-collapse>



			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import { core } from '../../../config/pluginInit';
// import { splice } from 'core-js/internals/enum-bug-keys';
import * as XLSX from 'xlsx';
import CDataTable from "@/components/common/CDataTable";
// import { json } from 'body-parser';
export default {
	name:'salesstatus',
    components:{
        CDataTable
    },
	data(){
		return{
            headers: [
                {text: '소싱방법\nBadge', value: '로켓뱃지', align: 'center', width: 140, isSlot: true},
				{text: '수집시간', value: '수집시간', align: 'center', width: 100, isSlot: false},
				{text: '수집자', value: 'collector', align: 'center', width: 100, isSlot: false},
                {text: '타겟키워드', value: '타겟키워드', align: 'center', width: 120, isSlot: false},
                {text: '상품정보', value: '판매상품명', align: 'center', width: 400, isSlot: true},
                {text: '구매정보', value: '구매가', align: 'center', width: 250, isSlot: true},
                {text: '매입가(원)', value: '매입가', align: 'center', width: 100, isSlot: false},
                {text: '예상월수익(원)', value: '월수익', align: 'center', width: 120, isSlot: false},
                {text: '예상마진(원)\n마진율(%)\nROI(%)', value: '마진', align: 'center', width: 120, isSlot: true},
                {text: '판매가(원)', value: '판매가', align: 'center', width: 150, isSlot: true},
                {text: '수수료(원)\n수수료율(%)', value: '수수료율', align: 'center', width: 100, isSlot: true},
                {text: '예상월판매량\n(개)', value: '월판매량', align: 'center', width: 120, isSlot: false},
                {text: '예상월매출(원)', value: '월매출', align: 'center', width: 120, isSlot: true},
                {text: '리뷰수', value: '리뷰수', align: 'center', width: 100, isSlot: false},
                {text: '리뷰평점', value: '리뷰평점', align: 'center', width: 100, isSlot: false},
                {text: '연관키워드', value: '연관키워드', align: 'center', width: 120, isSlot: false},
				{text: '메모', value: '메모', align: 'center', width: 120, isSlot: true},
            ],
			일괄구매가:'',
			일괄판매가:'',
			일괄매입가율:'',
            tableSelectedItem: [],
			salesRate:5,
			purchaseRatio:350,
			charge: 35,
            page: 1,

			myData : [1000,500,250,125,62,31,15,7,3,1,1,1,1,1,0],
			salesarr:[],
			importarr:[],
			stockarr:[],
			data:[],
			checkalllists:false,

			salescnt:0,
			importcnt:0,
			salesamount:0,
			supplyamount:0,
			noreview:0,
			productqty:0,
			nostock:0,
			// increview:0,
			// decreview:0,

			loadingtext:'데이터 불러오는 중 ...',


			title:"트렌드세터 소싱",
			desc1:"네이버 데이터랩에 접속 (또는 키워드리스트 엑셀파일 준비) 후 확장프로그램을 실행하여 \"Cntl+Shift+K\" 키를 입력해 주세요.",
			desc2:"트렌드세터 소싱을 체크 후에 상품을 수집하면 조건에 맞는 키워드와 소싱상품을 자동으로 수집합니다.",
			desc3:"수집할 때는 ip차단 방지를 위해 반드시 휴대폰 테더링을 이용해 주세요. 1000개 이상 수집 시 오래된 수집데이터가 자동으로 삭제됩니다.",
			link:{ name: 'people.addusers'},
			linktext:"판매현황 수집",

			SKUID:'',
			showspinner: false,
			startdate: this.getYesterday(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,

			maxrows : 200,
			minuslimit : -2,
			applyminuslimit : false,
			UserListS:[
				{
					name:'Cliff Hanger',
					email:'cliff@gmail.com',
					company:'Product Manager',
					group:'Cliff',
					status:'Active',
					store:'1'
				},
				{
					name:'Terry Aki',
					email:'terry@gmail.com',
					company:'Stock CEO',
					group:'Terry',
					status:'Active',
					store:'2'
				}
			],

		}
	},
    beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
    mounted() {
		this.messageEventHandler = async(event) => {
			if(event.data.res && event.data.res === 'tracking'){
                if(!event.data.msg.includes('수집완료')){
					this.loadingtext = '데이터 불러오는 중 ...';
					alert(event.data.msg)
					this.showspinner = false;
					this.showlist = true;
				} else {
					this.search()
				}
            }
            if(event.data.res && event.data.res === 'trackingratio'){
                this.loadingtext = '데이터 불러오는 중 ... ' + parseInt(event.data.ratio*100) + '%';
            }
		}
		window.addEventListener('message', this.messageEventHandler);
        this.$nextTick(function () {
            this.search()
        })
    },
	methods: {
		formatTimestamp(timestamp) {
			// Date 객체 생성
			const date = new Date(timestamp);

			// 년도, 월, 일, 시간, 분, 초를 각각 추출
			const year = date.getFullYear().toString().substr(-2); // 끝에서 두 자리만 추출
			const month = date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함
			const day = date.getDate();
			const hours = date.getHours();
			const minutes = date.getMinutes();
			const seconds = date.getSeconds();

			// 각 항목을 두 자리 숫자 형태로 포매팅
			const formattedMonth = month < 10 ? `0${month}` : month;
			const formattedDay = day < 10 ? `0${day}` : day;
			const formattedHours = hours < 10 ? `0${hours}` : hours;
			const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
			const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

			// 원하는 형식으로 조합하여 반환
			return `${year}.${formattedMonth}.${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		},
		editlists(){
			if(this.tableSelectedItem.length == 0){
				alert('변경할 내역을 선택해 주세요.')
				return
			}
			for(var e of this.tableSelectedItem){
				if(this.일괄구매가){
					e.구매가 = this.일괄구매가;
				}
				if(this.일괄매입가율){
					e.매입가율 = this.일괄매입가율
				}
				if(this.일괄판매가){
					e.판매가 = this.일괄판매가
				}
				this.changeCost(e)
			}
			this.일괄구매가 = '';
			this.일괄매입가율 = '';
			this.일괄판매가 = '';
		},
		selectline(){
			var pageInfo = core.checkpage();
			for(var i=0;i<this.saleslists.length;i++){
				if(i >= pageInfo.start && i < pageInfo.end){
					this.saleslists[i].checked = true
				}
			}
		},
		loadImage(src) {
			return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = src;

			img.onload = () => {
				resolve();
			};

			img.onerror = (error) => {
				reject(error);
			};
			});
		},
		showts(){
            this.showlist=false;
            setTimeout(() => {
                this.saleslists = this.saleslistsinit.filter(e => e.type == 'trendSetter');
                this.showlist = true;
            }, 1);
        },
		showtf(){
            this.showlist=false;
            setTimeout(() => {
                this.saleslists = this.saleslistsinit.filter(e => e.type == 'trendFollower');
                this.showlist = true;
            }, 1);
        },
		async previmg(saleslist,bool){
			saleslist.isLoading = true; // 이미지 로딩 중으로 설정
			// if(bool){
			// 	this.showlist = false;
			// }

			if(saleslist.currentindex == 0){
				saleslist.currentindex = saleslist.상품매칭.length - 1;
				return
			}
			saleslist.currentindex--

			// 이미지 변경 로직
			const newImageUrl = saleslist.상품매칭[saleslist.currentindex].pic_url;
			// 이미지를 미리 로드합니다.
			await this.loadImage(newImageUrl);
			// 이미지 변경 후
			saleslist.isLoading = false; // 로딩 완료로 설정
			if(bool){
				this.applyCurrentObj(saleslist);
			// 	setTimeout(()=>{
			// 		this.showlist = true;
			// 	},1)
			}
		},
		async nextimg(saleslist,bool){
			saleslist.isLoading = true; // 이미지 로딩 중으로 설정
			// if(bool){
			// 	this.showlist = false;
			// }
			if(saleslist.currentindex == saleslist.상품매칭.length - 1){
				saleslist.currentindex = 0;
				return
			}
			saleslist.currentindex++

			// 이미지 변경 로직
			const newImageUrl = saleslist.상품매칭[saleslist.currentindex].pic_url;
			// 이미지를 미리 로드합니다.
			await this.loadImage(newImageUrl);
			// 이미지 변경 후
			saleslist.isLoading = false; // 로딩 완료로 설정

			if(bool){
				this.applyCurrentObj(saleslist);
				// setTimeout(()=>{
				// 	this.showlist = true;
				// },1)
			}
		},
		applyCurrentObj(saleslist){
			var e = saleslist;
			saleslist.구매가 = e.상품매칭[e.currentindex].price;
			saleslist.월수익 = (e.판매가*0.9 - parseInt(saleslist.구매가*saleslist.매입가율))*parseInt(e.월매출/e.판매가)
			saleslist.마진 = e.판매가*0.9 - parseInt(saleslist.구매가*saleslist.매입가율)
			saleslist.마진율 = parseInt((e.판매가*0.9 - parseInt(saleslist.구매가*saleslist.매입가율))/e.판매가*100)
			saleslist.매입가 = parseInt(saleslist.구매가*saleslist.매입가율)
		},
		changeCost(saleslist){
			saleslist.매입가 = parseInt(saleslist.구매가*saleslist.매입가율);
			saleslist.수수료 = parseInt(saleslist.판매가*saleslist.수수료율*0.01);
			saleslist.부가세 = saleslist.판매가/11 - saleslist.구매가*saleslist.매입가율/11 - saleslist.수수료/11
			saleslist.마진 = parseInt(saleslist.판매가 - saleslist.매입가 - saleslist.수수료 - saleslist.부가세);
			saleslist.ROI = (saleslist.마진/saleslist.매입가)*100
			saleslist.마진율 = (saleslist.마진/saleslist.판매가)*100;
			saleslist.월수익 = (saleslist.마진*parseInt(saleslist.월매출/saleslist.판매가));
		},
		openWebsite(url) {
			window.open(url, "_blank");
		},
		linktopurchase(purchase){
			window.open(purchase.detail_url,"_blank");
		},
		applycorrect(){
			if(this.data.length > 0){
				this.showspinner = true;
				this.showlist = false;
				this.searchresult()
			} else {
				alert('추가된 소싱상품이 없습니다.')
				return
			}
		},
		checkalllist(){
			if(this.checkalllists){
				this.saleslists.forEach(e => {
					e.checked = true
				})
			} else {
				this.saleslists.forEach(e => {
					e.checked = false
				})
			}
		},
		saveitem(){
			if(this.tableSelectedItem.length == 0){
				alert('찜할 상품을 선택해 주세요')
			} else {
				if(confirm(this.tableSelectedItem.length + '개의 상품을 찜하시겠습니까?')){
					for(var ele of this.tableSelectedItem){
						ele.collector = ele.collector + "/" + this.$store.state.user.name
					}
					this.$store.commit('updateSaveItemStatus',true)
					axios.post('/api/nomad/addsaveitemM',this.tableSelectedItem)
					.then(result => {
						if(result.data.res == 'OK'){
							alert(result.data.cnt + '개 상품 찜 완료')
							console.log(result)
							var arr = [];
							for(var e of this.saleslists){
								if(!this.tableSelectedItem.find(f => f._id == e._id)){
									arr.push(this.deepClone(e))
								}
							}
							this.$store.commit('updateTS',arr)
							this.search();
						} else {
							if(result.data.res == 'Fail'){
								alert('장바구니에는 100개 이상 찜할 수 없습니다. (남은수량 : ' + result.data.remainQty + ')')
								console.log(result)
							} else {
								alert('찜 실패')
								console.log(result)
							}
						}
					})
				}
			}
		},
		saveStatus(){
			if(this.tableSelectedItem.length == 0){
				alert('저장할 항목을 선택해 주세요')
				return
			}
			if(confirm('현재 상태를 저장하시겠습니까?')){
				this.$store.commit('updateTS',this.saleslists)
				axios.post('/api/nomad/updatesourcingM',this.tableSelectedItem)
				.then(result => {
					if(result.data.res == 'OK'){
						alert('저장완료')
                        this.tableSelectedItem = []
						console.log(result)
					} else {
						alert(result.data.alert)
						console.log(result)
					}
				})
			}
		},
        changePage(eventValue) {
            this.page = eventValue
        },
		deleteitem(){
			if(this.tableSelectedItem.length == 0){
				alert('삭제할 상품을 선택해 주세요')
			} else {
				if(this.tableSelectedItem.length == this.saleslists.length){
					if(!confirm('전체 내역을 삭제하시겠습니까?')){
						return
					}
				} else {
					if(!confirm(this.tableSelectedItem.length + '개의 상품을 삭제하시겠습니까?')){
						return
					}
				}
				axios.post('/api/nomad/deleteitemM',this.tableSelectedItem)
				.then(result => {
					if(result.data.res == 'OK'){
						var arr = [];
						for(var e of this.saleslists){
							if(!this.tableSelectedItem.find(f => f._id == e._id)){
								arr.push(this.deepClone(e))
							}
						}
						this.$store.commit('updateTS',arr)
						this.search()
					} else {
						alert('삭제실패')
						console.log(result)
					}
				})
			}
		},
		collecttr(){
			window.postMessage({greeting: "collecttracking", companyid:this.$store.state.user.companyid},"*",)
			this.showspinner = true;
			this.showlist = false;
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		modalIDca(index) {
            // console.log(index);
            return 'modalca' + index
        },
		salesdownload(){
			if(this.saleslists.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			const workBook = XLSX.utils.book_new()
			var obj = {};
			var arr = [];
			this.saleslists.forEach(e => {
				arr = [];
				e.판매세부내역.forEach(ele => {
					obj = {
						상품명 : e.판매상품명,
						Date : ele.date,
						재고 : ele.재고,
						판매 : ele.판매,
						판매가 : ele.판매가,
						판매매출 : ele.판매매출,
						리뷰수 : ele.리뷰수,
						리뷰평점 : ele.리뷰평점
					}
					arr.push(obj)
				})
				var workSheet = XLSX.utils.json_to_sheet(arr)
				XLSX.utils.book_append_sheet(workBook, workSheet, e.vendorItemId.toString())
			})


			XLSX.writeFile(workBook, '일자별판매현황.xlsx')
		},
		search(){
			this.showspinner = true;
			this.showlist = false;
			this.saleslists = [];
			this.saleslistsinit = [];
			if(this.$store.state.TS.length > 0){
				this.$store.state.TS.forEach(e => {
					this.saleslists.push(this.deepClone(e))
					this.saleslistsinit.push(this.deepClone(e))
				})
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 10);
				return
			}
			axios.post('/api/nomad/getsourcingM',{companyid:this.$store.state.user.companyid,type:'trendSetter'})
			.then(result => {
                if(result.data.length > 0){
					console.log(result.data)
					this.searchresult(result.data)
                } else {
                    alert('추가된 모니터링 상품이 없습니다.')
                    return
                }
			})
		},
		datetotimestamp(a){
			var date = new Date(a);
			return date.getTime();
		},
        searchresult(data){
			data = data.filter(e => e.product.price);
            this.saleslists = [];
            this.saleslistsinit = [];
            var obj = {}
            var arr = [];
            data.forEach(e => {
				var currentindex = 0;
				if(e.판매상품명){
					arr.push(this.deepClone(e))
				} else {
					if(e.type == 'trendFollower'){
						if(e.product.type == 'wing'){
							var badge = ''
						}
						if(e.product.type == 'jet'){
							badge = 'https://image7.coupangcdn.com/image/coupang/rds/logo/iphone_2x/logoRocketMerchantLargeV3R3@2x.png'
						}
						if(e.product.type == 'rocket'){
							badge = 'https://image6.coupangcdn.com/image/cmg/icon/ios/logo_rocket_large@3x.png'
						}
						var 수집시간 = this.formatTimestamp(e.time);
						obj = {
							수집시간:수집시간,
							collector:e.collector,
							_id:e._id,
							checked : false,
							isLoading : false,
							썸네일 : e.imgUrl,
							판매상품명 : e.product.productName,
							productId : e.product.productId,
							판매가 : e.product.price,
							타겟키워드 : e.keyword,
							연관키워드 : '',
							리뷰수 : e.product.ratingcount,
							리뷰평점 : e.product.rating,
							type:e.type,
							구매가 : e.imageSearch[currentindex].price,
							월수익 : (e.product.price*0.9 - parseInt(e.imageSearch[currentindex].price*this.purchaseRatio))*parseInt(e.monthSales/e.product.price)*this.salesRate,
							마진 : e.product.price*0.9 - parseInt(e.imageSearch[currentindex].price*this.purchaseRatio),
							마진율 : parseInt((e.product.price*0.9 - parseInt(e.imageSearch[currentindex].price*this.purchaseRatio))/e.product.price*100),
							매입가 : parseInt(e.imageSearch[currentindex].price*this.purchaseRatio),
							매입가율 : this.purchaseRatio,
							수수료율 : this.charge,
							수수료 : parseInt(this.charge*e.product.price*0.01),
							월판매량 : parseInt(e.long)*this.salesRate,
							월매출 : parseInt(e.monthSales)*this.salesRate,
							상품매칭 : e.imageSearch,
							판매링크 : "https://www.coupang.com/vp/products/" + e.product.productId + "?vendorItemId=" + e.product.product.vendorItemId,
							currentindex : currentindex,
							로켓뱃지 : badge,
							메모 : e.메모
						}
						arr.push(obj)
					}
					if(e.type == 'trendSetter'){
						수집시간 = this.formatTimestamp(e.time);
						obj = {
							수집시간:수집시간,
							collector:e.collector,
							_id:e._id,
							checked : false,
							isLoading : false,
							썸네일 : e.product.imgUrl,
							판매상품명 : e.product.productName,
							productId : e.product.productId,
							판매가 : e.product.price,
							타겟키워드 : e.keyword,
							연관키워드 : e.relKeyword,
							리뷰수 : e.product.info.reviewAmount.totalReviewCount,
							리뷰평점 : e.product.info.reviewAmount.averageReviewScore,
							type:e.type,
							구매가 : parseInt(e.product.price*0.2/this.purchaseRatio),
							월수익 : 0,
							마진 : 0,
							마진율 : 0,
							매입가 : 0,
							매입가율 : this.purchaseRatio,
							수수료율 : this.charge,
							수수료 : parseInt(this.charge*e.product.price),
							월판매량 : parseInt(parseInt(e.keywordVolume)*this.salesRate/e.product.price),
							월매출 : parseInt(e.keywordVolume)*this.salesRate,
							상품매칭 : [],
							판매링크 : 'https://smartstore.naver.com/main/products/' + e.product.productId,
							currentindex : currentindex,
							로켓뱃지 : 'https://i.pinimg.com/736x/fb/71/04/fb71048e03a5ada757f70d61b583d0bf.jpg',
							메모 : e.메모
						}
						this.changeCost(obj)
						arr.push(this.deepClone(obj))
					}
				}
            })
			this.$store.commit('updateTS',arr);
            arr.forEach(e => {
                this.saleslists.push(this.deepClone(e))
                this.saleslistsinit.push(this.deepClone(e))
            })

            if(this.saleslists.length == data.length){
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 10);
            }
			console.log(this.saleslists)

        },
		timestamptodate(timestamp){
			var d = new Date(timestamp + (1000*60*60*9));
			return d.toISOString().replace('T',' ').slice(0,19)
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		todate(date){
			var today = new Date(date);
			today.setHours(today.getHours() + 9);
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getDatewith(d){
			var date = new Date(d);
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getYesterday(){
			return this.getDatewith(this.datetotimestamp(this.getTodaywith()) - 1000*60*60*24)
		},
		downloadsales() {
			if(this.tableSelectedItem.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			if(this.tableSelectedItem.length == 0){
                this.tableSelectedItem = this.saleslists
			}
			var arr = [];
			var obj = {};
            this.tableSelectedItem.forEach(e => {
				if(e.로켓뱃지 == 'https://image7.coupangcdn.com/image/coupang/rds/logo/iphone_2x/logoRocketMerchantLargeV3R3@2x.png'){
					var 로켓뱃지 = '판매자로켓'
				}
				if(e.로켓뱃지 == 'https://image6.coupangcdn.com/image/cmg/icon/ios/logo_rocket_large@3x.png'){
					로켓뱃지 = '로켓배송'
				}
				if(e.로켓뱃지 == ''){
					로켓뱃지 = '마켓플레이스'
				}
				obj = {
					썸네일 : e.썸네일,
					판매상품명 : e.판매상품명,
					상품번호 : e.productId,
					판매가 : e.판매가,
					타겟키워드 : e.타겟키워드,
					리뷰수 : e.리뷰수,
					리뷰평점 : e.리뷰평점,
					소싱타입 : e.type,
					월수익 : e.월수익,
					마진 : e.마진,
					마진율 : e.마진율,
					ROI : e.마진/e.매입가,
					매입가 : e.매입가,
					수수료 : e.수수료,
					월판매량 : e.월판매량,
					월매출 : e.월매출,
					로켓뱃지 : 로켓뱃지,
					판매상품링크 : e.판매링크,
					구매링크:'',
					수집시간 : e.수집시간,
					수집자 : e.collector,
					메모 : e.메모,
					'1.타겟키워드/상품간 매칭성(TS)':'',
					'2.지재권 문제':'',
					'3.마진율/ROI 만족여부':'',
					'4.타겟키워드 상품대비 차별성':'',
					'5.쿠팡 연관검색어에 동일상품 여부':'',
					'6.KC인증/전파인증/식검':'',
					'7.최근 1개월 가리뷰(TF)':'',
					'8.리뷰상 상품 문제점':'',
					'9.타겟키워드 재설정':'',
				}
				arr.push(obj)
			})


			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '경쟁사판매현황')
			XLSX.writeFile(workBook, '경쟁사판매현황' + '_' + getToday() + '.xlsx')
		}
	}

}
</script>

<style scoped>
.my-class .dropdown-menu {
	max-height: 300px;
	width:800px;
	overflow-y: auto;
}
.image-container {
  position: relative;
  display: inline-block;
}

.index-label {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8); /* 배경 색상 */
  padding: 4px 8px; /* 여백 조절 */
  border-radius: 4px; /* 모서리 둥글게 만들기 */
  font-size: 14px;
}
</style>
